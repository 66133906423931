@import "src/styles/variables";
@import "src/styles/media";

.order-form-wrapper {
  padding: 0 50px;
  
  .order-form-section {
    display: flex;
    padding: 20px 0;
    gap: 20px;

    .order-section-title {
      width: 400px;
      color: $primary-button-color;
      font-weight: bold;
      flex-shrink: 0;
    }

    .order-section-form {
      flex-grow: 1;
    }
  }
  
  .pricing-table {
    display: flex;
    flex-direction: column;
    border: 1px solid #575757;
    
    .pricing-unit {
      display: flex;
      
      &.table-header {
        background-color: #aaa7a7;
        font-weight: 700;
      }

      &:not(:last-child) {
        border-bottom: 1px solid #575757;
      }
      
      .pricing-unit-column {
        padding: 10px 15px;
        text-align: center;
        flex: 1;

        &:not(:last-child) {
          border-right: 1px solid #575757;
        }
      }
    }
  }
}

@media (max-width: $lg-max) {
  .order-form-wrapper {
    padding: 0 30px;

    .order-form-section {
      padding: 20px 0;

      .order-section-title {
        width: 250px;
      }
    }
  }
}

@media (max-width: $sm-max) {

  .order-form-wrapper {
    padding: 0 20px;

    .order-form-section {
      padding: 20px 0;
      flex-direction: column;

      .order-section-title {
        width: 100%;
      }
    }
  }
}

@media (max-width: $xs-max) {
}