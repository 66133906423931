@import "src/styles/variables";
@import "src/styles/media";

.order-form-wrapper {
  padding: 0 50px;

  .logos-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: 30px;
    margin-bottom: 80px;
    
    img {
      max-width: 200px;
      object-fit: contain;
    }
  }
  
  .measurements-table {
    display: flex;
    flex-direction: column;
    width: 300px;
    background-color: #aaa7a7;
    border: 1px solid #575757;
    margin-top: 30px;
    
    .measurement-unit {
      display: flex;
      width: 100%;
      
      &:not(:last-child) {
        border-bottom: 1px solid #575757;
      }

      .measurement-unit-name {
        flex: 1;
        max-width: 30%;
        color: $primary-color;
        border-right: 1px solid #575757;
        padding: 0 10px;
      }

      .measurement-unit-value {
        flex: 1;
        max-width: 70%;
        color: $secondary-color;
        padding: 0 10px;
      }
    }
  }
  
  .order-form-section {
    display: flex;
    padding: 20px 0;
    gap: 20px;

    .order-section-title {
      width: 400px;
      color: $primary-button-color;
      font-weight: bold;
      flex-shrink: 0;
    }

    .order-section-form {
      flex-grow: 1;
    }
  }
}

@media (max-width: $lg-max) {
  .order-form-wrapper {
    padding: 0 30px;

    .order-form-section {
      padding: 20px 0;

      .order-section-title {
        width: 250px;
      }
    }
  }
}

@media (max-width: $sm-max) {

  .order-form-wrapper {
    padding: 0 20px;

    .order-form-section {
      padding: 20px 0;
      flex-direction: column;

      .order-section-title {
        width: 100%;
      }
    }
  }
}

@media (max-width: $xs-max) {
}